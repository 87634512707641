<template>
  <v-container v-if="!is_open && !is_error" fill-heght>
    <div style="width:50px; height:50px; position:absolute; top:50%; left:50%; transform: translate(-50%, -50%);">
      <div class="spinner" />
    </div>
  </v-container>
  <v-container v-else-if="is_error" fill-heght>
    <div style="width:250px; height:50px; position:absolute; top:50%; left:50%; transform: translate(-50%, -50%); text-align:center;">
      에러가 발생했습니다.
    </div>
  </v-container>
  <v-container v-else fluid style="height:100%; background:linear-gradient(rgb(255, 255, 255), rgb(245, 249, 250));">
    <v-row align="center" justify="center" style="height:100%;">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="px-5 py-10" outlined>
          <v-form ref="form">
            <v-row align="center">
              <v-col>
                <div style="color:#808080; font-size:20px; text-align: center;">
                  <p>DTC 유전자검사 결과 확인</p>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col cols="5">
                <div>접수일자</div>
              </v-col>
              <v-col cols="7">
                <v-select v-model="request_date" @change="result_preview" dense outlined hide-details :items="request_date_list" no-data-text="데이터가 없습니다."></v-select>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col cols="5">
                <div>바코드번호(ID)</div>
              </v-col>
              <v-col cols="7">
                <div style="font-weight:bold;">{{ patient_id }}</div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col cols="5">
                <div>이름</div>
              </v-col>
              <v-col cols="7">
                <div style="font-weight:bold;">{{ patient_name }}</div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col cols="5">
                <div>진행 상황</div>
              </v-col>
              <v-col cols="7">
                <div style="font-weight:bold;">{{ progress }}</div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col cols="5">
                <div>예상 결과 완료일</div>
              </v-col>
              <v-col cols="7">
                <div style="font-weight:bold;">{{dateformat(forecast_date)}}</div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col cols="5">
                <div>실제 결과 완료일</div>
              </v-col>
              <v-col cols="7">
                <div style="font-weight:bold;">{{dateformat(finsh_date)}}</div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col cols="5">
                <div>결과보고일</div>
              </v-col>
              <v-col cols="7">
                <div style="font-weight:bold;">{{ dateformat(create_date) }}</div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row align="center">
              <v-col>
                <v-btn @click="print_result" :color="!!finsh_date?'':'grey'" :ripple="!!finsh_date" dark block :elevation="0">결과보고서 조회</v-btn>
              </v-col>
            </v-row>
            <div v-if="request_date_list&&request_date_list.length>0" class="mt-3" style="color:#acacac; font-size:10pt;">
              <div>
                궁금하신 사항은 아래의 이메일로 문의해 주시기 바랍니다.
              </div>
              <div style="text-align:center;">
                csdev@hlscience.com
              </div>
            </div>
            <div v-else class="mt-3" style="color:red; font-size:10pt; text-align: center;">
              분석결과가 없습니다
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import http from '@/mixin/http'
import validation from '@/mixin/validation'
export default {
  name: 'progress-vue',
  mixins: [validation, http],
  data:()=>({
    is_open: false,
    is_error: false,

    patient_id: null,
    patient_name: null,
    request_date: null,

    forecast_date: null,
    progress: null,
    finsh_date: null,
    create_date: null,
    now_request_date: null,

    template_version: '2',  //default 2

    request_date_list: [],
  }),
  created:async function(){
    if ( !this.$route.query.patient_id || !this.$route.query.patient_name ){
      alert("잘못된 접근입니다. 다시 로그인해주세요.");
      this.$session.destroy();
      this.$router.replace({name:"Login"});
      return;
    }
    this.patient_id = decodeURI(this.$route.query.patient_id);
    this.patient_name = decodeURI(this.$route.query.patient_name);
    this.get_request_date_combo().then(res=>{
      this.is_open = res;
    })
  },
  methods:{
    dateformat: function(str){
      if ( !str ) return;
      let date = new Date(str);
      let year = date.getFullYear();
      let month = date.getMonth()+1;
      let day = date.getDate();
      let res = "";
      if ( year ){
        res += ( year + '년');
      }
      if ( month ){
        res += ( ' ' + month + '월');
      }
      if ( day ){
        if ( day < 10 ){
          day = '0' + day;
        }
        res += ( ' ' + day + '일');
      }
      return res;
    },
    print_result:async function(){
      if ( this.request_date_list.lenght <= 0 ) return;
      if ( !this.finsh_date ) {
        alert("아직 검사결과가 완료되지 않았습니다.");
        return;
      }
      await this.get_result_template();
      let data = {
        headers:{
          jwt: this.$session.get("jwt")
        },
        param:[{
          patient_id: this.patient_id,
          request_date: this.request_date,
        }],
        template_version: this.template_version,
        expire: new Date().getTime()+1800000  //expire time : now() + 30min
      };
      let query_string = "?data=" + btoa(JSON.stringify(data));
      if ( this.template_version*1 == 4 ){
        window.open(process.env.NODE_ENV==='development'?"http://localhost:8081/v4"+query_string:"https://hlgenecheck.com/v4"+query_string, "", "");
      }
      else {
        window.open(process.env.NODE_ENV==='development'?"http://localhost:8081/viewer"+query_string:"https://hlgenecheck.com/viewer"+query_string, "", "");
      }
    },
    result_preview: async function(){
      let data = {
        patient_id: this.patient_id,
        request_date: this.request_date
      };
      let res = await this.get(this.$lookupUrl + '/server/result_preview.php', data);
      if ( res ){
        this.forecast_date = res.data.info.forecast_date;
        this.progress = res.data.info.progress;
        this.finsh_date = res.data.info.finsh_date;
        this.create_date = res.data.info.create_date;
        this.now_request_date = res.data.info.now_request_date;
      }
    },
    get_request_date_combo:async function(){
      let is_success = false;
      let data = {
        patient_id: this.patient_id
      };
      let res = await this.get(this.$lookupUrl + '/server/result/get_request_date_combo.php', data);
      if ( res ){
        res.data.info.forEach(element=>{
          this.request_date_list.push(element.request_date);
        })
        if ( this.request_date_list && this.request_date_list.length > 0 ){
          this.request_date = this.request_date_list[0];
          this.result_preview();
        }
        is_success = true;
      }
      else{
        this.is_error = true;
      }
      return is_success;
    },
    get_result_template:async function(){
      //템플릿 버전별 시작일자, 종료일자를 조회 하여 request_date가 포함되는 템플릿 버전을 세팅한다.
      //현재는 버전 1, 버전 2, 버전 4만 존재함.
      let data = {
        request_date: this.request_date
      };
      let res = await this.get(this.$lookupUrl + '/server/result/get_template_list.php', data);
      if ( res ){
        if ( res.data.ret == "0000" ){
          let template_info = res.data.info[0];
          this.template_version = template_info.seq;
        }
      }
    },
  }
}
</script>
